/**
 * KKH Fave-Bestandspflegebogen
 * This is a server where we handle family insurance portfolio maintenance sheet.
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressBvs } from './address';
import { PersonalInformationBvs } from './personal-information';


/**
 * This class contains all the general information for a family member i.e. the insurance
 */
export interface GeneralInformationBvs { 
    personalInformation: PersonalInformationBvs;
    address?: AddressBvs;
    /**
     * true, if the person has a different health insurance
     */
    differentHealthInsurance: boolean;
    /**
     * The current Health Insurance of the person, who is in the family insurance:    * private - Nicht gesetzlich krankenversichert    * insuredAsMember - Gesetzlich versichert als Mitglied   * familyInsurance - Familienversichert  Required, if differentHealthInsurance is true 
     */
    healthInsurance?: GeneralInformationBvs.HealthInsuranceBvs;
    /**
     * The name of the InsuranceCompany. Required, if differentHealthInsurance is true and healthInsurance is not private.
     */
    insuranceCompany?: string;
    /**
     * The first date of the existing insurance. Required, if differentHealthInsurance is true.
     */
    insuredFrom?: string;
    /**
     * The last date of the existing insurance.
     */
    insuredUntil?: string;
}
export namespace GeneralInformationBvs {
    export type HealthInsuranceBvs = 'private' | 'insuredAsMember' | 'familyInsurance';
    export const HealthInsuranceBvs = {
        Private: 'private' as HealthInsuranceBvs,
        InsuredAsMember: 'insuredAsMember' as HealthInsuranceBvs,
        FamilyInsurance: 'familyInsurance' as HealthInsuranceBvs
    };
}


