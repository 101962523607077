/**
 * KKH Fave-Bestandspflegebogen
 * This is a server where we handle family insurance portfolio maintenance sheet.
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PersonalInformationBitgo } from './personal-information';
import { AddressBitgo } from './address';


/**
 * This class contains all the general information for a family member i.e. the insurance
 */
export interface GeneralInformationBitgo { 
    personalInformation: PersonalInformationBitgo;
    address?: AddressBitgo;
    /**
     * true, if the person has a different health insurance
     */
    differentHealthInsurance: boolean;
    /**
     * The current Health Insurance of the person, who is in the family insurance:    * private - Nicht gesetzlich krankenversichert    * insuredAsMember - Gesetzlich versichert als Mitglied   * familyInsurance - Familienversichert  Required, if differentHealthInsurance is true 
     */
    healthInsurance?: GeneralInformationBitgo.HealthInsuranceBitgo;
    /**
     * The name of the InsuranceCompany. Required, if differentHealthInsurance is true and healthInsurance is not private.
     */
    insuranceCompany?: string;
    /**
     * The first date of the existing insurance. Required, if differentHealthInsurance is true.
     */
    insuredFrom?: string;
    /**
     * The last date of the existing insurance.
     */
    insuredUntil?: string;
}
export namespace GeneralInformationBitgo {
    export type HealthInsuranceBitgo = 'private' | 'insuredAsMember' | 'familyInsurance';
    export const HealthInsuranceBitgo = {
        Private: 'private' as HealthInsuranceBitgo,
        InsuredAsMember: 'insuredAsMember' as HealthInsuranceBitgo,
        FamilyInsurance: 'familyInsurance' as HealthInsuranceBitgo
    };
}


