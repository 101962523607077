import { ModuleWithProviders, NgModule } from '@angular/core';
import { MkkEnvironment } from '@mkk/env';
import { BASE_PATH as BASE_PATH_BVS, BvsApiModule } from './schemas/bvs';
import { BASE_PATH as BASE_PATH_BITGO, BitgoApiModule } from './schemas/bitgo';

function generateApiPath(path: string, env?: MkkEnvironment) {
  return `${env?.apiUrl ?? ''}/${path}`;
}

@NgModule({
  imports: [BitgoApiModule, BvsApiModule],
})
export class FaveBestandApiModule {
  public static forRoot(env?: MkkEnvironment): ModuleWithProviders<FaveBestandApiModule> {
    return {
      ngModule: FaveBestandApiModule,
      providers: [
        { provide: BASE_PATH_BVS, useFactory: () => generateApiPath('favebe/v1', env) },
        {
          provide: BASE_PATH_BITGO,
          useFactory: () => generateApiPath('favebe/v3', env),
        },
      ],
    };
  }
}
