/**
 * KKH Fave-Bestandspflegebogen
 * This is a server where we handle family insurance portfolio maintenance sheet.
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentBitgo } from './document';


/**
 * This Class contains Information about the person that is insured as a member
 */
export interface InsuredMemberBitgo { 
    /**
     * the maritalStatus of the person
     */
    maritalStatus: InsuredMemberBitgo.MaritalStatusBitgo;
    /**
     * If true then, the marital status changed before ExaminationPeriod. Required if maritalStatus is married, partnered, divorced or widowed
     */
    beforeExaminationPeriod?: boolean;
    /**
     * date on which marital status changed. Required if maritalStatus is married, partnered, divorced or widowed and beforeExaminationPeriod is false
     */
    maritalStatusSince?: string;
    /**
     * the email
     */
    email?: string;
    /**
     * the phone number
     */
    phoneNumber?: string;
    /**
     * Contains all documents of the insured member, that are needed for the family insurance application
     */
    documents?: Array<DocumentBitgo>;
}
export namespace InsuredMemberBitgo {
    export type MaritalStatusBitgo = 'single' | 'married' | 'partnered' | 'living_separately' | 'divorced' | 'widowed';
    export const MaritalStatusBitgo = {
        Single: 'single' as MaritalStatusBitgo,
        Married: 'married' as MaritalStatusBitgo,
        Partnered: 'partnered' as MaritalStatusBitgo,
        LivingSeparately: 'living_separately' as MaritalStatusBitgo,
        Divorced: 'divorced' as MaritalStatusBitgo,
        Widowed: 'widowed' as MaritalStatusBitgo
    };
}


