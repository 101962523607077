/**
 * KKH Fave-Bestandspflegebogen
 * This is a server where we handle family insurance portfolio maintenance sheet.
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * This class contains information about the document that needs to be uploaded
 */
export interface DocumentBvs { 
    /**
     * the file name of the file
     */
    filename?: string;
    /**
     * the description of the file
     */
    description?: string;
    /**
     * the extension of the file
     */
    fileExtension?: string;
    /**
     * Reference to file in MultiPart Array
     */
    documentReference?: number;
    /**
     * Flag when the document is subsequently submitted.
     */
    submitLater: boolean;
    /**
     * The current Health Insurance of the person, who is in the family insurance:    * proof_of_self_employment - Nachweis selbstständige-Tätigkeit   * proof_of_salary_for_marginal_employment - Gehaltsnachweis für geringfügige Beschäftigung   * proof_of_non_self_employment - Nachweis der Nicht-Selbstständigen Tätigkeit   * proof_of_pension - Rentennachweis   * proof_of_income_other_regular_income - Einkommensnachweis sonstige regelmäßige Einkünfte   * school_certificate - schulbescheinigung    * certificate_of_length_of_service - Dienstzeitbescheinigung 
     */
    documentType: DocumentBvs.DocumentTypeBvs;
}
export namespace DocumentBvs {
    export type DocumentTypeBvs = 'proof_of_self_employment' | 'proof_of_salary_for_marginal_employment' | 'proof_of_non_self_employment' | 'proof_of_pension' | 'proof_of_income_other_regular_income' | 'school_certificate' | 'certificate_of_length_of_service';
    export const DocumentTypeBvs = {
        ProofOfSelfEmployment: 'proof_of_self_employment' as DocumentTypeBvs,
        ProofOfSalaryForMarginalEmployment: 'proof_of_salary_for_marginal_employment' as DocumentTypeBvs,
        ProofOfNonSelfEmployment: 'proof_of_non_self_employment' as DocumentTypeBvs,
        ProofOfPension: 'proof_of_pension' as DocumentTypeBvs,
        ProofOfIncomeOtherRegularIncome: 'proof_of_income_other_regular_income' as DocumentTypeBvs,
        SchoolCertificate: 'school_certificate' as DocumentTypeBvs,
        CertificateOfLengthOfService: 'certificate_of_length_of_service' as DocumentTypeBvs
    };
}


